.content {
  a {
    text-decoration: none;
    &:hover {
      border-bottom: 1px solid $base-link-color;
      text-decoration: none;
    }
  }
}

img {
  &.circular {
    border-radius: 50%;
  }
  &.full-width {
    width: 100%;
  }
  &.border {
    border: 2px solid #E1E1E1;
  }
  &.border-with-spacing {
    border: 2px solid #E1E1E1;
    padding: 10px;
  }
}

// Content
@media screen and (max-width: $breakpoint-m) {
  .block-teaser {
    margin-bottom: 15px;
  }
}

.block-teaser-header {
  h3 {
    color: #AAA;
    font-family: Helvetica,Arial,sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
    @include font-size($base-h6-font-size);
  }
}

.col-logo-item {
  height: 150px;
  img {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 70%;
  }
}

.block-teaser {
  a {
    text-decoration: none;
    &:hover {
      border-bottom: none;
      h2, h3 {
        color: $base-link-color;
      }
    }
    h2, h3 {
      font-family: 'Noto Serif',Helvetica,Arial,sans-serif;
      @include font-size($base-h3-font-size);
    }
  }
  p {
    line-height: 160%;
  }
}

footer {
  margin-top: 80px;
  box-sizing: border-box;
  padding-top: 45px;
  padding-bottom: 45px;
  background: #1f1915;
  a:hover {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid $base-link-color;
    text-decoration: none;
  }
  ul {
    li {
      display: inline-block;
      padding: 10px;
      color: rgba(255,255,255,0.8);
    }
  }
}

@include breakpoint(m) {
  .col-logo-item {
    img {
      max-width: 95%;
    }
  }
}