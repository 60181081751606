// Header
header {
  .menu {
    margin: 30px;
    padding: 7px 7px 0 7px;
    background-color: #3b2921;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    &.has-visual {
      position: absolute;
    }

    .container {
      width: 100%;
    }

    a.logo {
      float: left;
      display: block;
      text-decoration: none;
      margin: -20px;
      img {
        width: 120px;
        float: left;
        margin: 0 10px 0 10px;
      }
      .logo-title {
        font-family: 'Noto Serif',Helvetica,Arial,sans-serif;
        @include line-height($base-h2-line-height);
        @include font-size($base-h2-font-size);
        display: block;
        width: 120px;
        float: left;
        margin-top: 25px;
        color: #FFF;
      }
    }
  }

  .header-visual {
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    &.has-image {
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 180px;
      padding-bottom: 150px;
      .visual-text {
        min-height: 220px;
      }
    }
    &.has-image-small {
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 200px;
      padding-bottom: 100px;
      .visual-text {
        min-height: 220px;
      }
    }
    &.has-visual-text {
      padding-top: 185px;
      padding-bottom: 40px;
    }
    &.background-color-gray {
      background-color: #f8f8f8;
    }

    .text-with-shadow {
      h1, h2, h3, h4, h5, h6 {
        display: table;
        margin-bottom: 5px;
        line-height: 50px;
      }
      p {
        display: inline;
        @include line-height($base-h1-line-height);
        @include font-size($base-h4-font-size);
      }
      * {
        background-color: rgba(100,152,79,0.9);
        text-shadow: 2px 2px #616161;
        color: #fff;
        padding: 7px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }
    }

    h1 {
      font-family: 'Noto Serif',Helvetica,Arial,sans-serif;
      font-weight: 400;
      padding-bottom: 10px;
    }

    p {
      font-family: 'Noto Serif',Helvetica,Arial,sans-serif;
      color: #666;
      @include line-height($base-h3-line-height);
      @include font-size($base-h4-font-size);

      .button {
        @include font-size($base-font-size);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-m) {
  header .menu {
    padding-left: 0;
    padding-right: 0;
    a.logo .logo-title {
      margin-top: 20px;
    }
    .container {
      padding: 0;
      .col-logo {
        z-index: 3;
      }
      .col-nav {
        z-index: 2;
      }
    }
  }
  header .header-visual {
    &.has-visual-text {
      padding-top: 160px;
    }
  }
}
@include breakpoint(m) {
  header .menu a.logo img { width: 110px; margin:4px 7px 0 0px; }
  header .menu .container { max-width: $container-m; }
  header .header-visual.has-image {
    padding-top: 204px;
    padding-bottom: 310px;
  }
}
// Large Device Container
@include breakpoint(l) {
  header .menu .container { max-width: $container-l; }
  header .menu a.logo img { width: 120px; margin: 0 20px 0 20px; }
}
// Extra Large Device Container
@include breakpoint(xl) {
  header .menu .container { max-width: $container-xl; }
  header .menu a.logo img { width: 130px; margin: 0 20px 0 20px; }
}
