.flash_messages {
  .flash {
    padding: 8px;
    margin-bottom: 10px;
    &.success {
      color: #468847;
      background-color: #dff0d8;
      border-color: #d6e9c6;
    }
    &.error {
      color: #b94a48;
      background-color: #f2dede;
      border-color: #eed3d7;
    }
    &.notice {
      color: #3a87ad;
      background-color: #d9edf7;
      border-color: #bce8f1;
    }
  }
}