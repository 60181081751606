/* form starting stylings ------------------------------- */
form {
  margin-top: 18px;
}
.form-group            {
  position:relative;
  margin-bottom: 35px;
}
input, textarea               {
  font-size:16px;
  padding:10px 10px 10px 5px;
  display:block;
  width:100%;
  border:none;
  border-bottom:1px solid #cacaca;
}
textarea {
  min-height: 60px;
  resize:vertical;
}
input:focus, textarea:focus         { outline:none; }

/* LABEL ======================================= */
label                {
  color:#999;
  font-size:16px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}

/* active state */
input:focus ~ label, input.form--filled ~ label, textarea:focus ~ label, textarea.form--filled ~ label {
  top:-20px;
  font-size:14px;
  color:#000;
}

/* BOTTOM BARS ================================= */
.bar    { position:relative; display:block; width:100%; }
.bar:before, .bar:after     {
  content:'';
  height:2px;
  width:0;
  bottom:1px;
  position:absolute;
  background:#64984f;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%;
}

/* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}

textarea:focus ~ .bar:before, textarea:focus ~ .bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position:absolute;
  height:60%;
  width:100px;
  top:25%;
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
input:focus ~ .highlight, textarea:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from { background:#64984f; }
  to    { width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
  from { background:#64984f; }
  to    { width:0; background:transparent; }
}
@keyframes inputHighlighter {
  from { background:#64984f; }
  to    { width:0; background:transparent; }
}