/** menu **/

#outer-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
}

#inner-wrap {
  position: relative;
  width: 100%;
}
.menu {
  a.toggle-button {
    display: none;
  }
}
#nav {
  position: relative;
  overflow: hidden;
  color: #fff;
  text-align: right;

  ul {
    *zoom: 1;
    display: block;
    margin: 2em 0 0 0;
    padding: 0;
    &:before, &:after {
      content: "";
      display: table;
    }
    li {
      a {
        color: #e0d7d2;
        text-decoration: none;
      }
      &:hover a {
        color: #FFF;
      }
      &.is-active a, &:hover.is-active a, &.parent-is-active a {
        color: #FFF;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  header .menu {
    margin: 30px 0;
  }
}
@media screen and (max-width: $breakpoint-m) {
  header .menu {
    margin: 30px 15px;
  }
  #top-bar {
    a.toggle-button {
      float: right;
      display: block;
      margin-top: 20px;
      margin-right: 10px;
      width: 25px;
      height: 28px;
    }
    a.toggle-button {
      position: relative;
      padding-left: 1.25em;
    }
    a.toggle-button:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.25em;
      width: 100%;
      height: 0.25em;
      background: white;
      box-shadow: 0 0.5em 0 0 white, 0 1em 0 0 white;
    }
    a.toggle-button:hover:before {
      background: #64984f;
      box-shadow: 0 0.5em 0 0 #64984f, 0 1em 0 0 #64984f;
    }
  }
  #nav {
    display: none;
  }
  .responsive {
    position: relative;
    #nav {
      position: relative;
      display: block;
      overflow: visible;
      .block {
        animation: SHW .3s;
        animation-fill-mode: both;
        background-color: #5d4439;
        position: absolute;
        width: 100%;
        top: 78px;
      }
      ul {
        margin: 1em 0 1em 0;
        li a {
          display: block;
          text-align: center;
        }
      }
    }
    li {
      float: none;
      display: block;
      text-align: left;
      padding: 0px;
      &:hover {
        background-color: rgba(70, 113, 53, 0.4);
      }
      &.is-active, &:hover.is-active, &.parent-is-active {
        background-color: rgba(70, 113, 53, 0.8);
      }
      a {
        padding: 15px;
      }
    }
  }
}

@keyframes SHW {
  from {
    opacity:0
  }
  to {
    opacity:1;
  }
}

@include breakpoint(m) {
  #nav {
    ul {
      white-space: nowrap;
    }
    li {
      display: inline-block;
      padding-bottom: 27px;
      &.is-active, &:hover.is-active, &.parent-is-active {
        border-bottom: 6px solid #64984f;
      }
      a {
        padding: 0.5em 0.4em;
        font-size: 110%;
        font-weight: 400;
      }
      &:hover {
        border-bottom: 6px solid #64984f;
      }
    }
  }
}
@include breakpoint(l) {
  #nav li a {
    padding: 10px 26px;
  }
}
@include breakpoint(xl) {
  #nav li, #nav li:hover {
    padding-bottom: 26px;
  }
}







//#nav .block {
//  z-index: 2;
//  position: relative;
//  padding: 0.75em 1.25em;
//}
//#nav ul
//#nav ul:after {
//  clear: both;
//}
//#nav li {
//  display: block;
//}
//#nav li a {
//  display: block;
//  color: #343434;
//  outline: none;
//  text-decoration: none;
//}
//#nav li a:focus, #nav li a:hover {
//  color: $base-link-color;
//}
//#nav li.is-active a {
//  color: $base-link-color;
//}
//
//@media screen and (min-width: 45.0625em) {
//
//}
//
//@media screen and (max-width: 45em) {
//  .nav-btn {
//    display: block;
//    width: 2.8em;
//    height: 2.8em;
//    padding: 0;
//    border: 0;
//    outline: none;
//    background: url("../img/nav-icon.svg") left center no-repeat;
//    background-size: 2.8em 2.8em;
//    overflow: hidden;
//    white-space: nowrap;
//    text-indent: 100%;
//    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
//    opacity: 0.7;
//    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//    margin: 8px 15px 0 3px;
//    float: left;
//  }
//  .no-svg .nav-btn {
//    background-image: url("../img/nav-icon.png");
//  }
//  .nav-btn:hover, .nav-btn:focus {
//    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
//    opacity: 1;
//  }
//
//  .close-btn {
//    display: block;
//    height: 2.25em;
//    border: 0;
//    outline: none;
//    background: #246D2D url("../img/close-btn.svg") left center no-repeat;
//    background-size: 1.875em 1.875em;
//    white-space: nowrap;
//    padding: 10px 10px 10px 2.25em;
//    color: #CCC;
//    text-decoration: none;
//    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
//    opacity: 1;
//    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//  }
//  .no-svg .close-btn {
//    background-image: url("../img/close-btn.png");
//  }
//
//  #nav {
//    position: absolute;
//    top: 0;
//    padding-top: 4em;
//    &:not(:target) {
//      z-index: 1;
//      height: 0;
//    }
//    &:target .close-btn {
//      display: block;
//    }
//    .close-btn {
//      position: absolute;
//      top: -3.75em;
//      left: 1.875em;
//    }
//    .block {
//      position: relative;
//      padding: 0;
//    }
//    ul {
//      padding: 0;
//    }
//    li {
//      position: relative;
//      border-top: 1px solid rgba(255, 255, 255, 0.1);
//      &:last-child {
//        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
//      }
//      &.is-active {
//        a {
//          font-weight: bold;
//          color: #FFF;
//        }
//        &:after {
//          z-index: 50;
//          display: block;
//          content: "";
//          position: absolute;
//          top: 50%;
//          right: -0.03125em;
//          margin-top: -0.625em;
//          border-top: 0.625em transparent solid;
//          border-bottom: 0.625em transparent solid;
//          border-right: 0.625em white solid;
//        }
//      }
//      a {
//        padding: 0.85714em 2.14286em;
//        color: #FFF;
//        &:hover {
//          color: #FFF;
//          font-weight: bold;
//        }
//        &:active {
//          font-weight: bold;
//          color: #FFF;
//        }
//      }
//    }
//  }
//  .js {
//    #nav {
//      height: 100%;
//      width: 70%;
//      background: #246D2D;
//      -webkit-box-shadow: inset -1.5em 0 1.5em -0.75em rgba(255, 255, 255, 0.15);
//      -moz-box-shadow: inset -1.5em 0 1.5em -0.75em rgba(255, 255, 255, 0.15);
//      box-shadow: inset -1.5em 0 1.5em -0.75em rgba(255, 255, 255, 0.15);
//      left: -70%;
//
//      .block {
//        background: transparent;
//      }
//      .close-btn {
//        display: block;
//        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
//        opacity: 0.7;
//      }
//      .close-btn:focus, .close-btn:hover {
//        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
//        opacity: 1;
//      }
//      #inner-wrap {
//        left: 70%;
//      }
//    }
//  }
//
//  .csstransforms3d.csstransitions.js #nav {
//    left: 0;
//    -webkit-transform: translate3d(-100%, 0, 0);
//    -moz-transform: translate3d(-100%, 0, 0);
//    -ms-transform: translate3d(-100%, 0, 0);
//    -o-transform: translate3d(-100%, 0, 0);
//    transform: translate3d(-100%, 0, 0);
//    -webkit-backface-visibility: hidden;
//    -moz-backface-visibility: hidden;
//    -ms-backface-visibility: hidden;
//    -o-backface-visibility: hidden;
//    backface-visibility: hidden;
//  }
//  .csstransforms3d.csstransitions.js #inner-wrap {
//    left: 0 !important;
//    -webkit-transform: translate3d(0, 0, 0);
//    -moz-transform: translate3d(0, 0, 0);
//    -ms-transform: translate3d(0, 0, 0);
//    -o-transform: translate3d(0, 0, 0);
//    transform: translate3d(0, 0, 0);
//    -webkit-transition: -webkit-transform 500ms ease;
//    -moz-transition: -moz-transform 500ms ease;
//    -o-transition: -o-transform 500ms ease;
//    transition: transform 500ms ease;
//    -webkit-backface-visibility: hidden;
//    -moz-backface-visibility: hidden;
//    -ms-backface-visibility: hidden;
//    -o-backface-visibility: hidden;
//    backface-visibility: hidden;
//  }
//
//  .csstransforms3d.csstransitions.js-nav #inner-wrap {
//    -webkit-transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//    -moz-transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//    -ms-transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//    -o-transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//    transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//  }
//
//  .csstransforms3d.csstransitions.js #nav .block {
//    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
//    opacity: 0.7;
//    -webkit-transition: opacity 300ms 100ms, -webkit-transform 500ms ease;
//    -webkit-transition-delay: ease, 0s;
//    -moz-transition: opacity 300ms 100ms ease, -moz-transform 500ms ease;
//    -o-transition: opacity 300ms 100ms ease, -o-transform 500ms ease;
//    transition: opacity 300ms 100ms ease, transform 500ms ease;
//    -webkit-transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//    -moz-transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//    -ms-transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//    -o-transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//    transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//    -webkit-transform-origin: 50% 0%;
//    -moz-transform-origin: 50% 0%;
//    -ms-transform-origin: 50% 0%;
//    -o-transform-origin: 50% 0%;
//    transform-origin: 50% 0%;
//  }
//
//  .csstransforms3d.csstransitions.js-nav #nav .block {
//    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
//    opacity: 1;
//    -webkit-transform: translate3d(0, 0, 0);
//    -moz-transform: translate3d(0, 0, 0);
//    -ms-transform: translate3d(0, 0, 0);
//    -o-transform: translate3d(0, 0, 0);
//    transform: translate3d(0, 0, 0);
//  }
//}