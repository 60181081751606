// ==========================================================================
// Base – Assets
// ==========================================================================

// Custom Buttons
.button {
  cursor: pointer;
  border: 1px solid #d7d7d7;
  background-color: #f3f3f3;
  line-height: normal;
  padding: 10px 20px;
  text-decoration: none;
  color: #363636;
  display: inline-block;
  transition: all 0.3s;
  &:hover, &:active { text-decoration: none; }
  &:hover { background: #f9f9f9; }
}
.button-primary {
  cursor: pointer;
  -webkit-appearance: none;
  margin: 0;
  border: none;
  font: inherit;
  color: #fff;
  display: inline-block;
  box-sizing: border-box;
  background: #64984f;
  @include line-height($base-h2-line-height);
  font-weight: $base-h2-font-weight;
  font-size: 90%;
  vertical-align: middle;
  //line-height: 40px;
  //min-height: 40px;
  text-decoration: none;
  text-align: center;
  padding: 3px 12px;
  border-radius: 0;
  z-index: 1;
  font-family: Helvetica,Arial,sans-serif;
  font-weight: normal;
  letter-spacing: 2px;
  -webkit-transition: all .12s ease-in-out;
  transition: all .12s ease-in-out;
  -webkit-backface-visibility: hidden;
  &:hover { color: $base-code-color }
}
.button-secondary {
  // Add your styles here
}

// Button Styled as Link
.button-link {
  color: $base-link-color;
  text-decoration: underline;
  border: 0;
  background: transparent;
  padding: 0;
  &:hover { text-decoration: none; }
  &:active { outline: 0; }
}